var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-col',{staticClass:"primary mt-4 mx-0 pa-6 white--text",attrs:{"cols":"12"}},[_c('v-row',[_c('div',{staticClass:"text-uppercase font-weight-bold"},[_vm._v("expense claims")])]),_c('v-row',{},[_c('div',{},[_vm._v(" Submitted by employees to claim back costs that they have personally paid for in relation to their work on behalf of their employer ")])])],1),_c('div',{staticClass:"pa-2 mt-5"},[_c('v-card-title',[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"From Date","prepend-icon":"mdi-calendar","outlined":"","readonly":""},model:{value:(_vm.from),callback:function ($$v) {_vm.from=$$v},expression:"from"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.fromMenu),callback:function ($$v) {_vm.fromMenu=$$v},expression:"fromMenu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.fromMenu = false}},model:{value:(_vm.from),callback:function ($$v) {_vm.from=$$v},expression:"from"}})],1),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"To Date","outlined":"","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.to),callback:function ($$v) {_vm.to=$$v},expression:"to"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.toMenu),callback:function ($$v) {_vm.toMenu=$$v},expression:"toMenu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.toMenu = false}},model:{value:(_vm.to),callback:function ($$v) {_vm.to=$$v},expression:"to"}})],1)],1),_c('v-card-title',[_c('v-icon',[_vm._v("mdi-text-search")]),_c('div',{staticClass:"text-uppercase"},[_vm._v("Search Results")]),_c('v-spacer'),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","outlined":"","label":"Search","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-card',{attrs:{"color":"transparent","flat":""}},[_c('v-row',[_c('v-col',[_c('v-dialog',{attrs:{"width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"primary"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" new claim")],1)]}}])},[_c('v-card',{staticClass:"mx-auto",attrs:{"width":""}},[_c('v-stepper',{model:{value:(_vm.stepper),callback:function ($$v) {_vm.stepper=$$v},expression:"stepper"}},[_c('v-stepper-header',[_c('v-stepper-step',{staticClass:"text-capitalize",attrs:{"complete":_vm.stepper > 1,"step":"1"}},[_vm._v(" Claims to make ")]),_c('v-stepper-step',{staticClass:"text-capitalize",attrs:{"complete":_vm.stepper > 2,"step":"2"}},[_vm._v(" amount to claim ")]),_c('v-stepper-step',{staticClass:"text-capitalize",attrs:{"step":"3"}},[_vm._v(" attach receipts ")])],1),_c('v-stepper-items',[_c('v-stepper-content',{attrs:{"step":"1"}},[_c('v-card',[_c('v-form',[_c('v-autocomplete',{attrs:{"items":[
                            'Accomodation',
                            'Meals',
                            'Medical',
                            'Mileage Expense' ],"chips":"","small-chips":"","label":"Select the claims you want to make","multiple":"","deletable-chips":"","auto-select-first":""}})],1),_c('div',{staticClass:"d-flex"},[_c('v-btn',{attrs:{"color":"primary"}},[_vm._v("cancel")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.stepper = 2}}},[_vm._v("next "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-chevron-right")])],1)],1)],1)],1),_c('v-stepper-content',{attrs:{"step":"2"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-form',{staticClass:"mt-2"},_vm._l(([
                            'Accomodation',
                            'Meals',
                            'Medical',
                            'Mileage Expense' ]),function(item){return _c('v-text-field',{key:item,attrs:{"outlined":"","label":item,"dense":""}})}),1)],1),_c('div',{staticClass:"d-flex"},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.stepper = 1}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-chevron-left")]),_vm._v(" previous")],1),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.stepper = 3}}},[_vm._v("next "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-chevron-right")])],1)],1)],1),_c('v-stepper-content',{attrs:{"step":"3"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-form',{staticClass:"mt-2"},_vm._l(([
                            'Accomodation',
                            'Meals',
                            'Medical',
                            'Mileage Expense' ]),function(item){return _c('v-file-input',{key:item,attrs:{"accept":"image/*","outlined":"","label":item,"dense":""}})}),1)],1),_c('div',{staticClass:"d-flex"},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.stepper = 2}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-chevron-left")]),_vm._v(" previous")],1),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"}},[_vm._v("finish")])],1)],1)],1)],1)],1)],1),_c('v-data-table',{staticClass:"text-capitalize elevation-10",attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"no-data-text":"No results for the current date black--text"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }