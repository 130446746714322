<template>
  <div>
    <v-col cols="12" class="primary mt-4 mx-0 pa-6 white--text">
      <v-row>
        <div class="text-uppercase font-weight-bold">expense claims</div>
      </v-row>
      <v-row class="">
        <div class="">
          Submitted by employees to claim back costs that they have personally
          paid for in relation to their work on behalf of their employer
        </div>
      </v-row>
    </v-col>

    <div class="pa-2 mt-5">
      <v-card-title>
        <v-menu
          v-model="fromMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="from"
              label="From Date"
              prepend-icon="mdi-calendar"
              outlined
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="from"
            @input="fromMenu = false"
          ></v-date-picker>
        </v-menu>

        <v-menu
          v-model="toMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="to"
              label="To Date"
              outlined
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="to" @input="toMenu = false"></v-date-picker>
        </v-menu>
      </v-card-title>

      <v-card-title>
        <v-icon>mdi-text-search</v-icon>
        <div class="text-uppercase">Search Results</div>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-text-field
          append-icon="mdi-magnify"
          outlined
          label="Search"
          dense
          v-model="search"
        ></v-text-field>
      </v-card-title>

      <v-card color="transparent" flat>
        <v-row>
          <v-col>
            <v-dialog width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" color="primary" class="mb-2">
                  <v-icon left>mdi-plus</v-icon> new claim</v-btn
                >
              </template>

              <v-card width="" class="mx-auto">
                <v-stepper v-model="stepper">
                  <v-stepper-header>
                    <v-stepper-step
                      :complete="stepper > 1"
                      step="1"
                      class="text-capitalize"
                    >
                      Claims to make
                    </v-stepper-step>
                    <v-stepper-step
                      :complete="stepper > 2"
                      step="2"
                      class="text-capitalize"
                    >
                      amount to claim
                    </v-stepper-step>
                    <v-stepper-step step="3" class="text-capitalize">
                      attach receipts
                    </v-stepper-step>
                  </v-stepper-header>

                  <v-stepper-items>
                    <v-stepper-content step="1">
                      <v-card>
                        <v-form>
                          <v-autocomplete
                            :items="[
                              'Accomodation',
                              'Meals',
                              'Medical',
                              'Mileage Expense',
                            ]"
                            chips
                            small-chips
                            label="Select the claims you want to make"
                            multiple
                            deletable-chips
                            auto-select-first
                          ></v-autocomplete>
                        </v-form>

                        <div class="d-flex">
                          <v-btn color="primary">cancel</v-btn>
                          <v-spacer></v-spacer>
                          <v-btn color="primary" @click="stepper = 2"
                            >next
                            <v-icon right>mdi-chevron-right</v-icon></v-btn
                          >
                        </div>
                      </v-card>
                    </v-stepper-content>

                    <v-stepper-content step="2">
                      <v-card flat>
                        <v-form class="mt-2">
                          <v-text-field
                            v-for="item in [
                              'Accomodation',
                              'Meals',
                              'Medical',
                              'Mileage Expense',
                            ]"
                            :key="item"
                            outlined
                            :label="item"
                            dense
                          ></v-text-field>
                        </v-form>
                      </v-card>

                      <div class="d-flex">
                        <v-btn color="primary" @click="stepper = 1">
                          <v-icon left>mdi-chevron-left</v-icon> previous</v-btn
                        >
                        <v-spacer></v-spacer>
                        <v-btn color="primary" @click="stepper = 3"
                          >next <v-icon right>mdi-chevron-right</v-icon></v-btn
                        >
                      </div>
                    </v-stepper-content>

                    <v-stepper-content step="3">
                      <v-card flat>
                        <v-form class="mt-2">
                          <v-file-input
                            v-for="item in [
                              'Accomodation',
                              'Meals',
                              'Medical',
                              'Mileage Expense',
                            ]"
                            accept="image/*"
                            :key="item"
                            outlined
                            :label="item"
                            dense
                          ></v-file-input>
                        </v-form>
                      </v-card>

                      <div class="d-flex">
                        <v-btn color="primary" @click="stepper = 2">
                          <v-icon left>mdi-chevron-left</v-icon> previous</v-btn
                        >
                        <v-spacer></v-spacer>
                        <v-btn color="primary">finish</v-btn>
                      </div>
                    </v-stepper-content>
                  </v-stepper-items>
                </v-stepper>
              </v-card>
            </v-dialog>
            <v-data-table
              :headers="headers"
              :items="items"
              :search="search"
              class="text-capitalize elevation-10"
              no-data-text="No results for the current date black--text"
            ></v-data-table>
          </v-col>
        </v-row>
      </v-card>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      toMenu: false,
      fromMenu: false,
      from: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      to: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      search: "",
      stepper: "1",
      headers: [
        {
          text: "recent claims",
          value: "date",
          class: "text-uppercase black--text",
        },
        {
          text: "status",
          value: "status",
          class: "text-uppercase black--text",
        },
        {
          text: "reason",
          value: "reason",
          class: "text-uppercase black--text",
        },
      ],
      items: [
        {
          date: "Dec 10, 2021 7:30 AM",
          status: "approved",
          reason: "lorem ipsum",
        },
        {
          date: "Dec 12, 2021 4:30 AM",
          status: "approved",
          reason: "lorem ipsum",
        },
        {
          date: "Dec 13, 2021 10:30 AM",
          status: "approved",
          reason: "lorem ipsum",
        },
        {
          date: "Dec 14, 2021 9:00 AM",
          status: "approved",
          reason: "lorem ipsum",
        },
        {
          date: "Dec 16, 2021 11:30 AM",
          status: "approved",
          reason: "lorem ipsum",
        },
        {
          date: "Dec 18, 2021 9:30 AM",
          status: "approved",
          reason: "lorem ipsum",
        },
        {
          date: "Dec 20, 2021 10:30 AM",
          status: "approved",
          reason: "lorem ipsum",
        },
      ],
    };
  },
};
</script>

<style></style>
